import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import { FaSistrix } from "react-icons/fa";
import Citrushero from "../components/hero/Citrushero";

function Citrus() {
  return (
    <Layout>
      <Citrushero />
      <Container>
        <Row>
          <Col lg={6} className="fifty-split-1">
            <h3>CITRUS COUNTY</h3>
            <p>
              Citrus County is part of the Nature Coast & the Adventure Coast
              and is considered the Manatee Capital of the World. This is the
              only place in the world you are able to legally interact and swim
              with manatees, Crystal River is a great place to find them.
            </p>
            <p>
              It is also famous for its unspoiled natural surroundings. Named
              for the county's citrus trees. It’s a great place to enjoy several
              different types of water recreations from fishing, boating,
              kayaking and tubing and yes swimming with a manatee.
            </p>
            <p>
              But if you are more of a land lover you can test your skills on
              over 20 exciting golf courses the county has to offer. Or from
              land you can visit the Boardwalks at Three Sisters Springs
              Wildlife Refuge which offer views of the marine mammal and the
              natural springs. Northwest is Crystal River Archaeological State
              Park, with remains of an early Native American settlement,
              including ceremonial mounds.
            </p>
            <p>
              There is also Homosassa Springs with the same crystal clear water
              by the springs and manatee’s to see. And when you are ready for
              some food and drink you can boat from restaurant and bars up and
              down the river!
            </p>
            <ul>
              <li>
                <FaSistrix /> The Withlacoochee River provides 45 miles of
                waterfront.
              </li>
              <li>
                <FaSistrix /> Tsala Apopka chain of lakes runs 22 miles long,
                covering 23,000 acres
              </li>
              <li>
                <FaSistrix /> Citrus County has 3 first magnitude springs:
                Crystal River/Kings Bay, Homosassa River, and Chasshowitzka
                River
              </li>
              <li>
                <FaSistrix /> Citrus County has 50 miles of paved trails and
                21.5 miles of unpaved trails to be used for walking, biking, and
                horseback riding.
              </li>
            </ul>
          </Col>
          <Col lg={6} className="fifty-split-2">
            <h5>General Faqs</h5>
            <ul>
              <li>
                <FaSistrix /> Population: 149,657 (2019 census)
              </li>
              <li>
                <FaSistrix /> 33rd Most Populous County in Florida
              </li>
              <li>
                <FaSistrix /> The 44th county established in Florida
              </li>
              <li>
                <FaSistrix /> Founded: June 2, 1887
              </li>
              <li>
                <FaSistrix /> County Seat: Inverness
              </li>
              <li>
                <FaSistrix /> There are 2 incorporated communities in Citrus:
                Inverness and Crystal River
              </li>
              <li>
                <FaSistrix /> Largest employment sectors: Medical and
                Government, Tourism based business
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container className="mt-30">
        <Row className="mb-30">
          <h3>Service Locations for Citrus County</h3>
        </Row>
        <Row>
          <Col lg={6} className="fifty-split-1">
            <ul>
              <li>
                <FaSistrix /> Crystal River
              </li>
              <li>
                <FaSistrix /> Inverness
              </li>
              <li>
                <FaSistrix /> Homosassa
              </li>
              <li>
                <FaSistrix /> Sugarmill Woods
              </li>
              <li>
                <FaSistrix /> Lecanto
              </li>
            </ul>
          </Col>
          <Col lg={6} className="fifty-split-2">
            <a
              href="/static/d777f5d2a2af9bb1ca2b55611d68b455/a217a/citrusmap.jpg"
              target="blank"
            >
              <StaticImage src="../images/citrusmap.jpg" />
            </a>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default Citrus;
